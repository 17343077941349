import { Logger } from '@kwara/lib/src/logger';

import { OrganisationT } from '@kwara/models/src/models/Organisation';

const headers = { 'Content-Type': 'application/json; charset=utf-8' };
export const USER_PIN_RESET_REQUIRED = 'PIN reset required';
export const USER_LOCKED = 'User is locked';

type SignupStatusResponse = {
  data: { attributes: { status: string; organisation_data: OrganisationT } };
};

export default class Api {
  constructor({ apiRoot }: { apiRoot?: string; appName?: string } = {}) {
    if (typeof apiRoot !== 'string') {
      throw new Error('apiRoot must be provided in config object');
    }

    this.apiRoot = apiRoot;
  }

  apiRoot: string;
  appName: string;

  async signupStatus(payload): Promise<SignupStatusResponse> {
    const params = new URLSearchParams(payload).toString();

    const url = `${this.apiRoot}/v1/auth/account_status?${params}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...headers
      }
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  /**
   * This method calls the login endpoint using a 5 PIN digit.
   */
  async connectUserToken({
    phone,
    pin
  }: {
    phone?: string;
    pin?: string;
  } = {}): Promise<{ jwt: string }> {
    if (!phone) {
      throw Error('phone must be provided');
    }

    if (!pin) {
      throw Error('pin must be provided');
    }

    const url = `${this.apiRoot}/auth/mobile_user_token.json`;

    const body = {
      auth: { phone, pin }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    const res = await response.json();
    // response.status === 400 check is specifically for Connect users that
    // have logged in with email and password in the past.
    // Connect backend returns status Code 400 with title: "PIN reset required", code: "LOGIN_FAILURE"
    // and this is handled in webapp-member/src/pages/Login/index.js.
    // When this is the case, FE takes the user to another page to create a new PIN
    if (!response.ok || response.status === 400) {
      throw res;
    }

    return res;
  }

  private static verifyConnectUserTokenV1Params(phone?: string, pin?: string) {
    if (!phone) throw Error('phone must be provided');

    if (!pin) throw Error('pin must be provided');
  }

  async connectUserTokenV1({
    phone,
    pin
  }: {
    phone?: string;
    pin?: string;
  } = {}): Promise<{ data: { attributes: { jwt: string } } }> {
    Api.verifyConnectUserTokenV1Params(phone, pin);

    const response = await fetch(`${this.apiRoot}/v1/auth/session`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ data: { attributes: { phone_number: phone, pin } } })
    });

    const res = await response.json();
    // response.status === 400 check is specifically for Connect users that
    // have logged in with email and password in the past.
    // Connect backend returns status Code 400 with title: "PIN reset required", code: "LOGIN_FAILURE"
    // and this is handled in webapp-member/src/pages/Login/index.js.
    // When this is the case, FE takes the user to another page to create a new PIN
    if (!response.ok) throw res;

    return res;
  }

  async userToken({
    email,
    password
  }: {
    email?: string;
    password?: string;
  } = {}): Promise<{ jwt: string }> {
    if (typeof email !== 'string') {
      throw Error('email must be provided');
    }

    if (typeof password !== 'string') {
      throw Error('password must be provided');
    }

    const url = `${this.apiRoot}/auth/user_token.json`;
    const body = {
      auth: {
        email,
        password
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    const res = await response.json();
    if (!response.ok) {
      throw res;
    }

    return res;
  }

  async deleteUserToken(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/auth/user_token`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  }

  async passwordForget(data) {
    const url = `${this.apiRoot}/password/forgot`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });

    //TO DO: backend should return response.ok whether password reset email is sent or not
    // https://support.logmeininc.com/gotomypc/help/why-didnt-i-get-my-reset-password-email-1153288921

    if (response.status >= 500) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return;
  }

  async passwordReset(data) {
    const url = `${this.apiRoot}/password/reset`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    return await response.json();
  }

  async refreshUserTokenConnect(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/v1/auth/session/refresh`;

    const body = {
      data: {
        attributes: {
          jwt: token
        }
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  }

  async refreshUserTokenCore(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/auth/token_refresh`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  }

  async resendUnlockLink(email: string) {
    const url = `${this.apiRoot}/auth/resend_unlock_email`;
    const body = {
      data: {
        attributes: {
          email
        }
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      // 2xx responses from this endpoint have no body,
      // so we just parse the body in case of error
      const err = await response.json();
      throw err;
    }

    return response;
  }

  async unlockAccount(token: string) {
    const url = `${this.apiRoot}/auth/unlock`;
    const body = {
      data: {
        attributes: {
          token
        }
      }
    };

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      // 2xx responses from this endpoint have no body,
      // so we just parse the body in case of error
      const err = await response.json();
      throw err;
    }

    return response;
  }

  async confirmEmail(token: string) {
    if (typeof token !== 'string') {
      throw new Error('token must be provided');
    }
    const url = `${this.apiRoot}/confirm?token=${token}`;

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  //Member app route
  async emailStatement(payload, token) {
    const params = new URLSearchParams(payload).toString();

    const url = `${this.apiRoot}/members/my_statement?${params}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async otpDevice(payload) {
    const url = `${this.apiRoot}/v1/auth/otp_device`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async verifyOTPV1(payload) {
    const url = `${this.apiRoot}/v1/auth/otp_device/verify`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }
    return await response;
  }

  async verifyIdentity(payload) {
    const url = `${this.apiRoot}/v1/auth/id_verification/verify`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response;
  }

  async resetFourPIN(payload) {
    const url = `${this.apiRoot}/v1/auth/pin/reset`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response;
  }

  async createAndActivateMember(payload) {
    const url = `${this.apiRoot}/v1/auth/user/create_and_activate`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async createUser(payload) {
    const url = `${this.apiRoot}/auth/signup/create_user`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async onboardMember(payload) {
    const url = `${this.apiRoot}/auth/signup/onboard`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  //Member app route
  async activateWithPIN(payload) {
    const url = `${this.apiRoot}/auth/signup/activate_with_pin`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async migrateToFourDigitsPin(token, payload) {
    const url = `${this.apiRoot}/v1/auth/pin/migrate_to_four_digits`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response;
  }

  async findPotentialGuarantor(
    token: string,
    apiNamespace: string,
    payload: { memberId: string; phoneNumber: string }
  ) {
    const { memberId, phoneNumber } = payload;

    const queryParams = [`phone_number=${phoneNumber}`, `member_id=${memberId}`].join('&');
    const url = `${this.apiRoot}${apiNamespace}/v1/guarantorship/potential_guarantor/find?${queryParams}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }
    return await response.json();
  }

  async sendReferralCode(payload) {
    const url = `${this.apiRoot}/referral_events`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      const err = await response.json();
      throw err;
    }

    return await response.json();
  }

  async createNewUser(payload) {
    const url = `${this.apiRoot}/signup`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers
    });

    if (!response.ok) {
      throw new Error(`API ERROR: ${response.statusText}`);
    }

    return await response.json();
  }

  async tillToken(token: string, tillId: string) {
    const url = `${this.apiRoot}/till_sessions`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: {
            till_id: tillId
          }
        }
      }),
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return true;
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  async removeTillToken(token: string, amount_back_to_vault: string) {
    const url = `${this.apiRoot}/till_sessions?amount_back_to_vault=${amount_back_to_vault}`;

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.ok) {
      return true;
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  async confirmOrganisation(path: string, token: string) {
    const url = `${this.apiRoot}${path}?t=${token}`;

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }

  async getHealthCheck() {
    const url = `${this.apiRoot}/health`;

    const requestInit = {
      method: 'GET',
      headers
    };

    try {
      const response = await fetch(url, requestInit);
      return response.status === 200;
    } catch (e) {
      Logger.log('Error health check ', JSON.stringify(e), JSON.stringify(requestInit));
      return false;
    }
  }

  async getQrCode(token: string) {
    if (!token) {
      throw new Error('token must be provided');
    }

    const url = `${this.apiRoot}/multiple_factor_authentication/totp/retrieve_qr_code`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    const secret = response.headers.get('kwara-mfa-authenticator-secret');
    const src = await response.blob().then(qrBlob => URL.createObjectURL(qrBlob));

    return { src, secret };
  }

  async verifyMfa(code: string, token: string) {
    const url = `${this.apiRoot}/multiple_factor_authentication/totp/verify`;

    const payload = { code };

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    });

    if (response.status === 403) {
      return { data: false };
    }

    if (response.ok) {
      return await response.json();
    }

    throw new Error(`API ERROR: ${response.statusText}`);
  }
}
