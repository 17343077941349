import jwtDecode from 'jwt-decode';
import get from 'lodash/fp/get';
import { JwtPayload } from 'jwt-decode';

import { Logger } from '@kwara/lib/src/logger';
import { clearFreshchatClientCache } from '@kwara/components/src/Freshchat/util';

import Api from './Api';
import { setFavicon } from './favicon';

export const TOKEN_KEY: string = 'kwara-jwt';

function hasExpired(exp: number) {
  return exp * 1000 < Date.now();
}

interface JwtDecodedPayload extends Omit<JwtPayload, 'sub'> {
  sub?: {
    login_activity_id: number;
    mfa_activated: boolean;
    mfa_verified: boolean;
    user: number;
    api: string;
  };
}

type AuthConstructor = {
  api?: Api;
  storage?: Storage;
};

interface OtpData {
  data: {
    attributes: {
      device_token: string;
    };
  };
}

export default class Auth<Permissions = any> {
  api: Api;
  storage: Storage;
  decoded: JwtDecodedPayload | null;
  permissions: Array<Permissions>;

  constructor({ api, storage = window.localStorage }: AuthConstructor = {}) {
    this.api = api;
    this.storage = storage;
    this.decoded = null;
  }

  isV1() {
    return get('decoded.sub.api', this) == 'v1';
  }

  isDuara() {
    return get('decoded.sub.api', this) == 'v1d';
  }

  confirmEmail = (token: string) => {
    return this.api.confirmEmail(token);
  };

  getRawToken = () => {
    return this.storage.getItem(TOKEN_KEY);
  };

  getUserId = () => {
    return get('sub.user', this.decoded);
  };

  isMfaVerified = () => {
    return get('sub.mfa_verified', this.getToken());
  };

  isMfaActivated = () => {
    return get('sub.mfa_activated', this.getToken());
  };

  getExpiryDate = () => {
    const token = this.getToken();

    if (token != null && token.exp) {
      return new Date(token.exp * 1000);
    }

    return null;
  };

  getToken = (): JwtDecodedPayload | null => {
    // This method gets called multiple times so we cache the result
    // to avoid having to decode the same token multiple times
    if (this.decoded && !hasExpired(this.decoded.exp)) {
      return this.decoded;
    }

    const apiToken = this.getRawToken();

    if (apiToken == null) {
      return null;
    }

    try {
      const decoded = jwtDecode<JwtDecodedPayload>(apiToken);

      if (decoded && decoded.exp) {
        if (hasExpired(decoded.exp)) {
          this.clearLocalData();

          return null;
        } else {
          this.decoded = decoded;

          return decoded;
        }
      }
    } catch (error) {
      Logger.error(`Cannot decode JWT`, JSON.stringify(error));
    }

    return null;
  };

  isTillOpen = () => {
    const token = this.getToken();

    if (token == null) {
      return false;
    }

    return get('sub.till.state', token) === 'OPEN';
  };

  setPermissions = (permissions = []) => {
    this.permissions = [...Object.freeze(permissions)];
  };

  getPermissions = () => {
    return this.permissions;
  };

  startTillSession = async (tillId: string) => {
    if (this.isLoggedIn()) {
      try {
        return await this.api.tillToken(this.getRawToken(), tillId);
      } catch (err) {
        Logger.error('API error opening till', err);
        return false;
      }
    }
  };

  endTillSession = async ({ amountBackToVault }: { amountBackToVault: string }) => {
    if (this.isLoggedIn()) {
      try {
        return await this.api.removeTillToken(this.getRawToken(), amountBackToVault);
      } catch (err) {
        Logger.error('API error closing till', err);
        return false;
      }
    }
  };

  isLoggedIn = () => {
    return this.getToken() != null;
  };

  connectLogIn = async (payload: { phone: string; pin: string }) => {
    const { jwt } = await this.api.connectUserToken(payload);

    this.updateStorage(jwt);
  };

  connectWithFourDigitLogIn = async (payload: { phone: string; pin: string }) => {
    const res = await this.api.connectUserTokenV1(payload);

    const { data } = res;

    const { attributes } = data;

    const { jwt } = attributes;

    this.updateStorage(jwt);
  };

  logIn = async (payload: { email: string; password: string }) => {
    const { jwt } = await this.api.userToken(payload);

    this.updateStorage(jwt);
  };

  updateStorage = (jwt: string) => {
    this.storage.setItem(TOKEN_KEY, jwt);
  };

  logOut = async () => {
    if (this.isLoggedIn()) {
      try {
        await this.api.deleteUserToken(this.getRawToken());
      } catch (err) {
        Logger.error('API error logging out', err);
      } finally {
        setFavicon();
        this.clearLocalData();
        clearFreshchatClientCache();
      }
    }

    return;
  };

  //Connect deleteUserToken endpoint has been deprecated
  logOutConnect = () => {
    if (this.isLoggedIn()) {
      setFavicon();
      this.clearLocalData();
    }

    clearFreshchatClientCache();
  };

  emailStatement = (payload: { date_from: string; date_to: string; email: string }) => {
    return this.api.emailStatement(payload, this.getRawToken());
  };

  optDevice = (payload: {
    data: { attributes: { phone_number: string; id_type: string; id_number: string } };
  }): Promise<OtpData> => {
    return this.api.otpDevice(payload);
  };

  createUser = (payload: {
    data: {
      attributes: {
        phone_number: string;
        org_permalink: string;
        id_type: string;
        id_number: string;
      };
    };
  }) => {
    return this.api.createUser(payload);
  };

  verifyOTPV1 = (payload: { data: { attributes: { phone_number: string; otp: string; device_token: string } } }) => {
    return this.api.verifyOTPV1(payload);
  };

  verifyIdentity = (payload: {
    data: {
      attributes: { phone_number: string; id_type: string; id_number: string; device_token: string };
    };
  }) => {
    return this.api.verifyIdentity(payload);
  };

  createAndActivateMember = (payload: {
    data: {
      attributes: {
        phone_number: string;
        pin: string;
        id_type: string;
        id_number: string;
        device_token: string;
      };
    };
  }) => {
    return this.api.createAndActivateMember(payload);
  };

  resetFourPIN = (payload: {
    data: { attributes: { phone_number: string; newPin: string; id_type: string; id_number: string } };
  }) => {
    return this.api.resetFourPIN(payload);
  };

  getQrCode = (): Promise<{ src: string; secret: string | null }> => {
    return this.api.getQrCode(this.getRawToken());
  };

  verifyMfa = async (payload: string) => {
    const res = await this.api.verifyMfa(payload, this.getRawToken());
    this.decoded = null;
    this.updateStorage(res.jwt);

    return res;
  };

  activateWithPIN = payload => {
    return this.api.activateWithPIN(payload);
  };

  migrateToFourDigitsPin = async (payload: { phone_number: string; new_pin: string }) => {
    if (this.isLoggedIn()) {
      try {
        return await this.api.migrateToFourDigitsPin(this.getRawToken(), payload);
      } catch (err) {
        Logger.error('API error migrating to Four PIN', err);
        return false;
      }
    }
  };

  findPotentialGuarantor = async (payload: { memberId: string; phoneNumber: string }) => {
    if (this.isLoggedIn()) {
      const apiNamespace = this.isDuara() ? '/duara' : '';
      return await this.api.findPotentialGuarantor(this.getRawToken(), apiNamespace, payload);
    }
  };

  sendReferralCode = (payload: {
    data: { attributes: { phone_number: string; current_pin: string; referral_code: string } };
  }) => {
    return this.api.sendReferralCode(payload);
  };

  onboardMember = payload => {
    return this.api.onboardMember(payload);
  };

  passwordForget = (data: { email: string }) => {
    return this.api.passwordForget(data);
  };

  passwordReset = (data: { password: string; passwordConfirmation: string; token: string }) => {
    return this.api.passwordReset(data);
  };

  confirmOrganisation = (path: string, token: string) => {
    return this.api.confirmOrganisation(path, token);
  };

  getHealthCheck = () => {
    return this.api.getHealthCheck();
  };

  resendUnlockLink = (data: string) => {
    return this.api.resendUnlockLink(data);
  };

  unlockAccount = (t: string) => {
    return this.api.unlockAccount(t);
  };

  signUp = <Payload>(payload: Payload) => {
    return this.api.createNewUser(payload);
  };

  signupStatus = <Payload>(payload: Payload) => {
    return this.api.signupStatus(payload);
  };

  refreshTokenConnect = async () => {
    if (this.isLoggedIn() && !hasExpired(this.decoded.exp)) {
      try {
        const response = await this.api.refreshUserTokenConnect(this.getRawToken());
        const data = response.data;
        const { jwt } = data.attributes;
        this.storage.setItem(TOKEN_KEY, jwt);
        this.decoded = null;
      } catch (err) {
        Logger.error('API error refreshing token in Connect', err);
      }
    }
  };

  refreshTokenCore = async () => {
    if (this.isLoggedIn() && !hasExpired(this.decoded.exp)) {
      try {
        const response = await this.api.refreshUserTokenCore(this.getRawToken());
        const jwt = response.jwt;
        this.storage.setItem(TOKEN_KEY, jwt);
        this.decoded = null;
      } catch (err) {
        Logger.error('API error refreshing token in Core', err);
      }
    }
  };

  clearLocalData = () => {
    this.decoded = null;
    this.storage.removeItem(TOKEN_KEY);
  };
}
